import * as React from 'react';
import { Icon, Input, Button} from 'semantic-ui-react';
import { format } from 'date-fns';
import screenfull from 'screenfull';
import { findDOMNode } from 'react-dom';
import { PlayerState } from './player_state';

interface PlayerControlsProps {
  state: PlayerState;
  dispatch: React.Dispatch<any>;
  wrapperRef: React.RefObject<HTMLDivElement>;
  playerRef: React.RefObject<any>;
  hasPlayed: boolean;
  blockForward: boolean;
}

const PlayerControls: React.FC<PlayerControlsProps> = ({ state, dispatch, wrapperRef, playerRef, hasPlayed, blockForward}) => {

  const handleSound = (_event: React.ChangeEvent<HTMLInputElement>, { value }: { value: number }) => {
    localStorage.setItem("video_sound", value.toString());
    dispatch({ type: 'VOLUME', payload: value });
  };

  const handleFullscreen = () => {
    if (screenfull.isEnabled && wrapperRef.current) {
      screenfull.toggle(findDOMNode(wrapperRef.current) as Element);
    }
  };

  const handleSeek = (_event: React.ChangeEvent<HTMLInputElement>, { value }: { value: number }) => {
    // Ensure that we only allow seeking backward for all users except SuperAdmin
    if (value <= state.progress.playedSeconds ||blockForward === false) {
      playerRef.current.seekTo(value);
      dispatch({ type: 'SEEK', payload: value });
    }
  };

  return (
    <div
      style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        padding: '10px 10px 5px 10px',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
         borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        opacity: hasPlayed ? 1 : 0,
        transition: 'opacity 0.2s ease-in-out',
        display: 'flex',
        flexDirection: 'column',
        gap: '2px',
        overflow: 'hidden',
      }}
    >
      <Input
        type="range"
        min={0}
        max={state.duration}
        step={0.01}
        value={state.progress.playedSeconds || 0}
        onChange={(e) => handleSeek(e, { value: parseFloat(e.target.value) })}
        className="video-player__slider--seek"
      />

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '0px'}}>
        <Button icon onClick={() => dispatch({ type: 'TOGGLE_PLAY' })}
        style={{ flex: '0 0 auto',backgroundColor: 'transparent', boxShadow: 'none' }}>
          <Icon name={state.playing ? 'pause' : 'play'} size="large"  style={{color:'white'}}/>
        </Button>

        <div style={{ display: 'flex', alignItems: 'center', flex: '0 0 auto' }}>
          <Icon name="volume down" style={{color:'white'}}/>
          <Input
            type="range"
            max={1}
            step={0.01}
            value={state.volume}
            onChange={(e) => handleSound(e, { value: parseFloat(e.target.value) })}
            className="video-player__slider--seek"
            style={{ width: '100px', margin: '0 5px'}}
          />
          <Icon name="volume up" style={{color:'white'}}/>
        </div>

        <label style={{ flex: '0 0 auto', margin: '0 5px', color:'white'}}>
          {format(new Date(state.progress.playedSeconds * 1000), 'mm:ss')} /{' '}
          {format(new Date(state.duration * 1000), 'mm:ss')}
        </label>

        <Button icon onClick={handleFullscreen}
        style={{ flex: '0 0 auto', backgroundColor: 'transparent', boxShadow: 'none'  }}>
          <Icon name="expand" size="large" style={{color:'white'}}/>
        </Button>
      </div>
    </div>
  );
};

export default PlayerControls;