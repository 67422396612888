import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { DocumentTitle } from "../../app/components/document_title";
import { useAuth } from "../../app/context/auth_provider";
import { Location } from "../../models/account/location";
import { Result } from "../../models/common/paged_result";
import { api } from "../../shared/axios_wrapper";
import { LocationListView } from "./component/location_list_component";
import { UserRole } from "../../models/user/user";
import { useNavigate } from "react-router-dom";
import { Button } from "semantic-ui-react";

function LocationListPage() {
    const {user} = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<Location[]>([] as Location[]);

    useEffect(() => {
        if (user){
            setLoading(true);
            const url = `account/${user.accountId}/location/list/`;
            api.get<Result<Location[]>>(url)
            .then((r) => {
                setData(r.data.result);
            })
            .catch((e) => { toast.error("Error", { theme: "colored" }) })
            .finally(() => setLoading(false));
        }
    }, [user]);

    return(<>
        <DocumentTitle title="Locations" />
        {((user?.role === UserRole.SuperAdmin) || (user?.accountId === 6 && user?.role >= UserRole.LocationAdmin)) && (
        <Button icon="podcast" content="interLIFT - Generate Schedule Package"
                onClick={() => navigate("/admin/interlift/schedule-package")}
        />
        )}
       {!loading && <LocationListView locationList={data} />}
    </>);

}
export default LocationListPage;