import React, { useState } from "react";
import { Table, Button, Popup } from "semantic-ui-react";
import { ScheduleCourseDto, WeekDay } from "../../models/interlift/interlift_schedule";
import { getEnumText } from "../../shared/utils";

type ScheduleTableProps = {
  days: number[];
  schedule: Map<WeekDay, Map<string, ScheduleCourseDto>>;
  handleEditLinkClick: (day: WeekDay, slot: string) => void;
  handleDeleteLinkClick: (day: WeekDay, slot: string) => void;
  handleAddSlot: (day: WeekDay, slot: string) => void;
};

const generateTimeSlots = (): string[] => {
  const slots: string[] = [];
  const start = new Date();
  start.setHours(6, 0, 0, 0); // 6:00 AM

  const end = new Date();
  end.setHours(17, 30, 0, 0); // 5:30 PM

  while (start <= end) {
    const hour = start.getHours();
    const minute = start.getMinutes();
    const formatted = `${(hour % 12 || 12)
      .toString()
      .padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${
      hour < 12 ? 'AM' : 'PM'
    }`;
    slots.push(formatted);
    start.setMinutes(start.getMinutes() + 30);
  }

  return slots;
};


const timeSlots = generateTimeSlots();

const ScheduleTable: React.FC<ScheduleTableProps> = ({
  days,
  schedule,
  handleEditLinkClick,
  handleDeleteLinkClick,
  handleAddSlot,
}) => {
  const [hoveredCell, setHoveredCell] = useState<{ day: WeekDay; slot: string } | null>(null);

  return (
    <Table celled selectable compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Time</Table.HeaderCell>
          {days?.map((dayNumber) => (
            <Table.HeaderCell key={dayNumber} textAlign="center">{getEnumText(WeekDay, dayNumber)}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {timeSlots?.map((slot) => (
          <Table.Row key={slot}>
            <Table.Cell>{slot}</Table.Cell>
            {days?.map((dayNumber) => {
              const day = dayNumber as WeekDay;
              const course = schedule.get(day)?.get(slot);
              return (
                <Popup
                  key={day}
                  open={hoveredCell?.day === day && hoveredCell?.slot === slot}
                  content={
                    course ? (
                      <div>
                        {!course.interLiftSchedule ? (
                          // Show only instructions if interLiftSchedule is true
                          <div>
                            <strong>Instructions:</strong> {course.coverSheetInstructions || 'N/A'}
                          </div>
                        ) : (
                          // Show all details except instructions if interLiftSchedule is false
                          <div>
                            <strong>{course.scheduledCode}</strong> <br />
                            <strong>Course:</strong> {course.courseName || 'N/A'} <br />
                            <strong>Module:</strong> {course.moduleCode || 'N/A'} - {course.moduleName || 'N/A'} <br />
                            NR: Y, Q: {course.includeQuiz ? 'Y' : 'N'}, A: {course.includeActivity ? 'Y' : 'N'}, GR: {course.includeGRMaterial ? 'Y' : 'N'}
                          </div>
                        )}
                      </div>
                    ) : null
                  }
                  position="top center"
                  trigger={
                    <Table.Cell
                      style={{ backgroundColor: course ? "#ffffcc" : "#ffffff", textAlign: "center", cursor: "pointer" }}
                      onMouseEnter={() => setHoveredCell(course ? { day, slot } : null)}
                      onMouseLeave={() => setHoveredCell(null)}
                      onDoubleClick={() => {
                        if (course) {
                          handleEditLinkClick(day, slot);
                        } else {
                          handleAddSlot(day, slot);
                        }
                      }}
                    >
                      {course ? (
                        <>
                          <div style={{ fontWeight: "bold"}}>
                            {course.scheduledCode ??
                            (course.coverSheetInstructions.length > 10 ?
                            course.coverSheetInstructions.slice(0, 10) : course.coverSheetInstructions)}&nbsp;&nbsp;
                            <Button
                              size="mini"
                              color="red"
                              icon="delete"
                              basic
                              circular
                              onClick={() => handleDeleteLinkClick(day, slot)}
                            ></Button>
                          </div>
                          {course.interLiftSchedule && <div style={{ fontSize: "0.8em", marginTop: "2px" }}>
                            NR:Y, Q:{course.includeQuiz ? "Y" : "N"}, A:{course.includeActivity ? "Y" : "N"}, GR:{course.includeGRMaterial ? "Y" : "N"}
                          </div>}
                        </>
                      ) : (
                        ""
                      )}
                    </Table.Cell>
                  }
                />
              );
            })}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default ScheduleTable;
