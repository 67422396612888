import React, { useEffect, useState } from 'react';
import { Table, Button, Icon } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { campaignApi } from '../../shared/axios_wrapper';
import { toast } from 'react-toastify';
import { formatDate } from '../../shared/utils';
import { DocumentTitle } from '../../app/components/document_title';
import { CampaignUserMessage } from '../../models/campaign/campaign';
import { Result } from '../../models/common/paged_result';
import Papa from 'papaparse';

const CampaignUserMsgReport = () => {
  const { campaignId, campaignUserId } = useParams<{ campaignId: string; campaignUserId: string }>();
  const campaignIdInt = parseInt(campaignId ?? '0');
  const campaignUserIdInt = parseInt(campaignUserId ?? '0');
  const [messages, setMessages] = useState<CampaignUserMessage[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (campaignIdInt <= 0 || campaignUserIdInt <= 0) {
      toast.error("Invalid Campaign or User ID");
      return;
    }

    setLoading(true);
    campaignApi.get<Result<CampaignUserMessage[]>>(`/campaign-user-msg/report/${campaignIdInt}/${campaignUserIdInt}`)
      .then(response => setMessages(response.data?.result))
      .catch(() => toast.error("Unable to load campaign user messages"))
      .finally(() => setLoading(false));
  }, [campaignIdInt, campaignUserIdInt]);

  const campaignName = messages.length > 0 ? messages[0].campaignName : '';
  const userPhone = messages.length > 0 ? messages[0].userPhone : '';

   const handleDownloadCSV = () => {
    if (messages.length === 0) {
      toast.warn('No data available to download');
      return;
    }

    const csvData = messages.map(message => ({
      campaign_name: message.campaignName,
      msg_prefix: message.msgPrefix,
      msg_header: message.msgHeader,
      msg_footer: message.msgFooter,
      user_phone: message.userPhone,
      opt_in_date: formatDate(message.optInDate),
      opt_out_date: formatDate(message.optOutDate),
      completed_date: formatDate(message.completedDate),
      msg_id: message.msgId,
      message: message.msg,
      is_question: message.isQuestion ? 'Yes' : 'No',
      day: message.day,
      hour: message.hour,
      minute: message.minute,
      delivery_date: formatDate(message.deliveryDate),
      response: message.response,
      response_time: formatDate(message.responseTime)
    }));

    // Convert JSON data to CSV using PapaParse
    const csv = Papa.unparse(csvData);

    // Create a blob and a download link for the CSV file
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `campaign_user_msg_report_${campaignIdInt}_${campaignUserIdInt}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <DocumentTitle title="Campaign User Messages Report" />
      <div>
        <h1>Campaign User Messages Report</h1>

        {/* Display campaign name and user phone above the table */}
        <div style={{ marginBottom: '20px' }}>
          <h3>Campaign: {campaignName}</h3>
          <h4>User Phone: {userPhone}</h4>
        </div>

        <div><a href={`/admin/campaign-users/${campaignIdInt}`}>&lt;&lt; Campaign Users</a></div>

        <Button secondary onClick={handleDownloadCSV} style={{ marginLeft: '10px' }}>
          <Icon name='download' /> Download CSV
        </Button>

        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign='center'>Message ID</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Message</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Prefix</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Header</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Footer</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>User Phone</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Opt-In Date</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Opt-Out Date</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Day</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Hour</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Minute</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Delivery Date</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Response</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Response Time</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {messages.map((message, index) => (
              <Table.Row key={index}>
                <Table.Cell textAlign='center'>{message.msgId}</Table.Cell>
                <Table.Cell>{message.msg}</Table.Cell>
                <Table.Cell textAlign='center'>{message.msgPrefix}</Table.Cell>
                <Table.Cell textAlign='center'>{message.msgHeader}</Table.Cell>
                <Table.Cell textAlign='center'>{message.msgFooter}</Table.Cell>
                <Table.Cell textAlign='center'>{message.userPhone}</Table.Cell>
                <Table.Cell textAlign='center'>{formatDate(message.optInDate)}</Table.Cell>
                <Table.Cell textAlign='center'>{formatDate(message.optOutDate)}</Table.Cell>
                <Table.Cell textAlign='center'>{message.day}</Table.Cell>
                <Table.Cell textAlign='center'>{message.hour}</Table.Cell>
                <Table.Cell textAlign='center'>{message.minute}</Table.Cell>
                <Table.Cell textAlign='center'>{formatDate(message.deliveryDate)}</Table.Cell>
                <Table.Cell textAlign='center'>{message.response}</Table.Cell>
                <Table.Cell textAlign='center'>{formatDate(message.responseTime)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {loading && <p>Loading...</p>}
      </div>
    </>
  );
};

export default CampaignUserMsgReport;
