import * as React from 'react';
import { OverlayState } from './player_state';

interface PlayerOverlayProps {
  state: OverlayState;
}

const PlayerOverlay: React.FC<PlayerOverlayProps> = ({ state }) => {
  const overlayStyle: React.CSSProperties = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    pointerEvents: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    left: 0,
    top: 0,
    backgroundColor: state.playing ? 'transparent' : 'rgba(0, 0, 0, 0.4)',
    opacity: state.playing ? 0 : 1,
    transition: 'opacity 0.2s ease-in-out',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  };

  const innerStyle: React.CSSProperties = {
    paddingLeft: state.light ? '50px' : '25px',
    paddingBottom: state.light ? '50px' : '38px',
    width: state.light ? 'auto' : '100%',
  };

  return (
    <div style={overlayStyle}>
      <div style={innerStyle} className="video-player__overlay-inner">

      </div>
    </div>
  );
};

export default PlayerOverlay;
