import React, { useState, useEffect } from 'react';
import { Modal, Dropdown, Button, Checkbox, Radio, Grid, Input, Label } from 'semantic-ui-react';
import { ScheduleCourseDto, ModulePart, WeekDay } from '../../models/interlift/interlift_schedule';
import { toast } from 'react-toastify';
import { getEnumText } from '../../shared/utils';

type ScheduleModalProps = {
  open: boolean;
  slot: ScheduleCourseDto | null;
  isEditing: boolean;
  onSave: (updatedSlot: ScheduleCourseDto) => void;
  onClose: () => void;
  existingSlots: ScheduleCourseDto[];
  courseModules: ScheduleCourseDto[];
  selectedDay: WeekDay | null;
  selectedSlot: string | null;
};

const ScheduleModal: React.FC<ScheduleModalProps> = ({
  open,
  slot,
  isEditing,
  onSave,
  onClose,
  existingSlots,
  courseModules,
  selectedDay,
  selectedSlot,
}) => {
  const [selectedCourse, setSelectedCourse] = useState<string>(slot?.courseName || '');
  const [selectedModule, setSelectedModule] = useState<string>(slot?.moduleCode || '');
  const [selectedModuleName, setSelectedModuleName] = useState<string>(slot?.moduleName || '');
  const [selectedModulePart, setSelectedModulePart] = useState<ModulePart | undefined>(slot?.modulePart);
  const [includeQuiz, setIncludeQuiz] = useState<boolean>(slot?.includeQuiz || false);
  const [includeActivity, setIncludeActivity] = useState<boolean>(slot?.includeActivity || true);
  const [includeGRMaterial, setIncludeGRMaterial] = useState<boolean>(slot?.includeGRMaterial || true);
  const [scheduledCode, setScheduledCode] = useState<string>(slot?.scheduledCode || '');
  const [selectedHour, setSelectedHour] = useState<string>(selectedSlot || '');
  const [selectedInstructions, setSelectedInstructions] = useState<string>(slot?.coverSheetInstructions || '');
  const [interLiftSchedule, setInterLiftSchedule] = useState<boolean>(slot?.interLiftSchedule ?? true); // Default to true

  useEffect(() => {
    if (isEditing && slot) {
      setSelectedCourse(slot.courseName || '');
      setSelectedModule(slot.moduleCode || '');
      setSelectedModuleName(slot.moduleName || '');
      setSelectedModulePart(slot.modulePart);
      setIncludeQuiz(slot.includeQuiz || false);
      setIncludeActivity(slot.includeActivity || true);
      setIncludeGRMaterial(slot.includeGRMaterial || true);
      setSelectedHour(slot.slot || '');
      setSelectedInstructions(slot.coverSheetInstructions || '');
      setInterLiftSchedule(slot.interLiftSchedule ?? true); // Default to true
    }
  }, [isEditing, slot]);

  const handleModuleChange = (moduleCode: string) => {
    const selectedModule = courseModules.find(module => module.moduleCode === moduleCode);
    if (selectedModule) {
      setSelectedModule(moduleCode);
      setSelectedModuleName(selectedModule.moduleName || '');
      generateScheduledCode(selectedModulePart, moduleCode);
    }
  };

  const generateScheduledCode = (modulePart: ModulePart | undefined, moduleCode: string) => {
    if (modulePart && moduleCode) {
      const numberPart = getNumberPart(modulePart);
      const letterPart = getLetterPart(modulePart);
      const generatedScheduledCode = `${numberPart}${moduleCode}${letterPart}`;
      setScheduledCode(generatedScheduledCode);
    }
  };

  const getNumberPart = (modulePart: ModulePart): string => {
    switch (modulePart) {
      case ModulePart.FR1:
        return '1';
      case ModulePart.FR2:
        return '2';
      case ModulePart.FR3:
        return '3';
      case ModulePart.FR4:
        return '4';
      case ModulePart.SR1A:
      case ModulePart.SR1B:
        return '1';
      case ModulePart.SR2A:
      case ModulePart.SR2B:
        return '2';
      default:
        return '';
    }
  };

  const getLetterPart = (modulePart: ModulePart): string => {
    switch (modulePart) {
      case ModulePart.FR1:
      case ModulePart.FR2:
      case ModulePart.FR3:
      case ModulePart.FR4:
        return 'F';
      case ModulePart.SR1A:
      case ModulePart.SR2A:
        return 'A';
      case ModulePart.SR1B:
      case ModulePart.SR2B:
        return 'B';
      default:
        return '';
    }
  };

  const isPrepToParentCourse = (courseName: string): boolean => {
    return courseName.toLowerCase() === 'prep to parent'.toLowerCase();
  };

  const modulePartOptions = Object.entries(ModulePart)
    .filter(([key]) => isNaN(Number(key)))
    .map(([key, value]) => ({
      key: value,
      value,
      text: key,
    }));

  const filteredModulePartOptions = isPrepToParentCourse(selectedCourse)
    ? modulePartOptions.filter(option => option.value === ModulePart.FR1)
    : modulePartOptions;

  const handleInterLiftScheduleToggle = (checked: boolean) => {
    setInterLiftSchedule(checked);

    // Clear fields when switching between on/off
    if (checked) {
      // Switching to InterLIFT on: Clear instructions
      setSelectedInstructions('');
    } else {
      // Switching to InterLIFT off: Clear all InterLIFT-specific fields
      setSelectedCourse('');
      setSelectedModule('');
      setSelectedModuleName('');
      setSelectedModulePart(undefined);
      setIncludeQuiz(false);
      setIncludeActivity(true);
      setIncludeGRMaterial(true);
      setScheduledCode('');
    }
  };

  const isUniqueCombination = (): boolean => {
    if (!interLiftSchedule) return true; // Skip check if InterLIFT is off

    const isDuplicate = existingSlots.some(
      (slot) =>
        slot.courseName === selectedCourse &&
        slot.moduleCode === selectedModule &&
        slot.modulePart === selectedModulePart &&
        (!isEditing || slot.slot !== selectedHour || slot.day !== selectedDay) // Exclude the current slot if editing
    );

    return !isDuplicate;
  };

  const handleSave = () => {
    if (!selectedDay || !selectedHour) {
      toast.error('Day and Slot must be selected before saving.');
      return;
    }

    if (interLiftSchedule && (!selectedCourse || !selectedModule || !selectedModulePart)) {
      toast.error('All fields must be selected before saving.');
      return;
    }

    if (!interLiftSchedule && !selectedInstructions) {
      toast.error('Instructions must be provided when InterLIFT is off.');
      return;
    }

    if (interLiftSchedule && !isUniqueCombination()) {
      toast.error('This combination of Course, Module, and Module Part already exists.');
      return;
    }

    const updatedSlot: ScheduleCourseDto = {
      day: selectedDay,
      slot: selectedHour,
      interLiftSchedule,
      coverSheetInstructions: selectedInstructions,
      ...(interLiftSchedule && {
        courseName: selectedCourse,
        moduleCode: selectedModule,
        moduleName: selectedModuleName,
        modulePart: selectedModulePart,
        includeQuiz,
        includeActivity,
        includeGRMaterial,
        scheduledCode,
      }),
    };

    onSave(updatedSlot);
  };

  return (
    <Modal open={open} onClose={onClose} closeIcon>
      <Modal.Header>{isEditing ? 'Edit Slot' : 'Add Slot'}</Modal.Header>
      <Modal.Content>
        <Grid relaxed={false}>
          {/* Row 1: Day and Slot */}
          <Grid.Row columns={2}>
            <Grid.Column width={6}>
              <Dropdown
                selection
                value={selectedDay ?? 0}
                disabled
                options={[{ key: selectedDay ?? 0, value: selectedDay ?? 0, text: getEnumText(WeekDay, selectedDay ?? 0) }]}
              />
            </Grid.Column>
            <Grid.Column width={6}>
              <Dropdown
                placeholder="Select Time Slot"
                selection
                search
                value={selectedHour}
                disabled
                options={[{ key: selectedHour, value: selectedHour, text: selectedHour }]}
              />
            </Grid.Column>
          </Grid.Row>

          {/* InterLIFT Toggle */}
          <Grid.Row>
            <Grid.Column>
              <Checkbox
                label="InterLIFT Pre-Produced"
                checked={interLiftSchedule}
                onChange={(e, { checked }) => handleInterLiftScheduleToggle(!!checked)}
              />
            </Grid.Column>
          </Grid.Row>

          {/* Conditional Fields */}
          {interLiftSchedule ? (
            <>
              {/* Row 2: Course and Module Selection */}
              <Grid.Row columns={2}>
                <Grid.Column width={6}>
                  <Dropdown
                    placeholder="Select Course"
                    fluid
                    selection
                    search
                    value={selectedCourse}
                    onChange={(e, { value }) => setSelectedCourse(value as string)}
                    options={Array.from(new Set(courseModules?.map(course => course.courseName)))?.map(courseName => ({
                      key: courseName,
                      value: courseName,
                      text: courseName,
                    }))}
                  />
                </Grid.Column>
                <Grid.Column width={6}>
                  <Dropdown
                    placeholder="Select Module"
                    fluid
                    selection
                    search
                    value={selectedModule}
                    onChange={(e, { value }) => handleModuleChange(value as string)}
                    options={courseModules
                      .filter(course => course.courseName === selectedCourse)
                      .map(course => ({
                        key: course.moduleCode,
                        value: course.moduleCode,
                        text: `${course.moduleCode} - ${course.moduleName}`,
                      }))}
                  />
                </Grid.Column>
              </Grid.Row>

              {/* Row 3: Module Part Selection and Checkboxes */}
              <Grid.Row columns={2}>
                <Grid.Column width={6}>
                  <Grid columns={3} stackable>
                    {filteredModulePartOptions?.map((part, index) => (
                      <Grid.Column key={part.key}>
                        <Radio
                          label={part.text}
                          name="modulePart"
                          value={part.value}
                          checked={selectedModulePart === part.value}
                          onChange={() => {
                            setSelectedModulePart(part.value as ModulePart);
                            generateScheduledCode(part.value as ModulePart, selectedModule);
                          }}
                        />
                      </Grid.Column>
                    ))}
                  </Grid>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Grid columns={4} stackable>
                    <Grid.Row>
                      <Grid.Column>
                        <Checkbox
                          label="Include Quiz"
                          checked={includeQuiz}
                          onChange={(e, { checked }) => setIncludeQuiz(!!checked)}
                          disabled={isPrepToParentCourse(selectedCourse)}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Checkbox
                          label="Include Activity"
                          checked={includeActivity}
                          onChange={(e, { checked }) => setIncludeActivity(!!checked)}
                          disabled={isPrepToParentCourse(selectedCourse)}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Checkbox
                          label="Include GR Material"
                          checked={includeGRMaterial}
                          onChange={(e, { checked }) => setIncludeGRMaterial(!!checked)}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <Label>{scheduledCode}</Label>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </>
          ) : (
            <Grid.Row>
              <Grid.Column>
                <Input
                  type="text"
                  value={selectedInstructions}
                  maxLength={50}
                  placeholder="Custom Instructions"
                  style={{ width: '100%' }}
                  onChange={(e) => setSelectedInstructions(e.target.value)}
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Cancel</Button>
        <Button primary onClick={handleSave}>
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ScheduleModal;