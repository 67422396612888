import { DropdownItemProps } from "semantic-ui-react";
import { PageParams, SortOrder } from "../models/common/paged_result";
const fullSizeWidth = 'min-width:768px';

interface CacheItem<T> {
  value: T;
  expiry: number;
}

export function setLocalStorageWithExpiry<T>(key: string, value: T, ttl: number): void {
  const now = new Date();
  const item: CacheItem<T> = {
      value: value,
      expiry: now.getTime() + ttl * 1000 // TTL is in seconds
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getLocalStorageWithExpiry<T>(key: string): T | null {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
      return null;
  }
  const item: CacheItem<T> = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
  }
  return item.value;
}

export const getFullSizeWidth = () => fullSizeWidth;

export const addDays = (date: Date, days: number) => new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
export const subtractDays = (date: Date, days: number) => new Date(date.getTime() - days * 24 * 60 * 60 * 1000);

export const formatFullDate = (date: any) => {
  return new Date(date).toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' });
}

export const formatDate = (value: any, convertUtcToLocal: boolean = false) => {
  try{
    if (!value || value === undefined || value === "") {
      return "";
    }
    const date = new Date(value);

    if (isNaN(date.getTime())) {
      // The date is invalid, return a blank string
      return "";
    }

    const locale = navigator.language || "en-US";

    if (convertUtcToLocal) {
      // Convert UTC to local time
      const localDate = new Date(date.toLocaleString(locale, { timeZone: "UTC" }));
      return localDate.toLocaleString(locale, { month: '2-digit', day: '2-digit', year: 'numeric' });
    }

    return date.toLocaleString(locale, { month: '2-digit', day: '2-digit', year: 'numeric' });

  }
  catch{
    return "";
  }
}

export const isValidDate = (date: Date | string | null | undefined): boolean => {
  if (!date) return false;

  // If date is already a Date object, check its validity directly
  if (date instanceof Date) {
      return !isNaN(date.getTime());
  }

  // Otherwise, assume it's a string and try to parse it
  const parsedDate = new Date(date);
  return !isNaN(parsedDate.getTime());
};

export const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export const handleGridSort = (params: any, clickedColumn: string) => {
  const sort = { ...params };
  let newOrder: SortOrder = params.sortOrder === 'ascending' ? 'descending' : 'ascending';
  if (params.sortIndex !== clickedColumn) {
    newOrder = 'ascending';
  }
  sort.sortIndex = clickedColumn;
  sort.sortOrder = newOrder;
  sort.pageIndex = 0;
  return sort;
}

export const handleGridSearch = (params: any, value: PageParams) => ({
  ...params, search: value, pageIndex: 0
});

export const handleGridNavigation = (params: any, updatedParams: PageParams) =>
({ ...params, pageIndex: updatedParams.pageIndex, pageSize: updatedParams.pageSize });

export const booleanOptions: DropdownItemProps[] = [
  { key: "False", value: 0, text: "No" },
  { key: "True", value: 1, text: "Yes" },
];

export const enumToKeyValueList = (enumObject: { [key: string]: string | number }) => {
  return Object.entries(enumObject)
    .filter(([key, value]) => typeof value === "number") // Ensure we only map numeric values
    .map(([key, value]) => ({
      key: value,
      value,
      text: key,
    }));
};

export function getEnumValue(myEnum: any, enumValue: string | number) {
  let keys = Object.keys(myEnum).filter(x => myEnum[x] === enumValue || x === enumValue);
  return keys.length > 0 ? parseInt(keys[0]) : 0;
}

export function getEnumText(myEnum: any, enumValue: string | number) {
  let keys = Object.keys(myEnum).filter(x => myEnum[x] === enumValue || x === enumValue);
  return keys.length > 0 ? keys[0] : "";
}

export function replaceNullWithEmptyString(obj: any) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] === null) {
        obj[key] = "";
      }
    }
  }
}

export const getDistinctByProperty = <T, K extends keyof T>(list: T[], propertyName: K): T[] => {
  if (!list) return [];
  const uniqueValues = new Set(list.map(item => item[propertyName]));
  return Array.from(uniqueValues).map(value => list.find(item => item[propertyName] === value)!);
};
