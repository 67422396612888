import React, { useEffect, useState } from 'react';
import { Button, Confirm, Message, Table } from 'semantic-ui-react';
import Papa from 'papaparse';
import { campaignApi } from '../../shared/axios_wrapper';
import { DocumentTitle } from '../../app/components/document_title';
import { toast } from 'react-toastify';
import { CampaignMessage, CampaignMsgList } from '../../models/campaign/campaign';
import { useNavigate, useParams } from 'react-router-dom';
import { Result } from '../../models/common/paged_result';

const CampaignMessageBulkUpload = () => {
  const navigate = useNavigate();
  const { campaignId } = useParams<{ campaignId: string }>();
  const campaignIdInt = parseInt(campaignId ?? '0');
  const [campaignName, setCampaignName] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  //const [messages, setMessages] = useState('');
  const [csvData, setCsvData] = useState<CampaignMessage[]>([]);
  const [fileError, setFileError] = useState<string | null>(null);

  useEffect(() => {
    if (campaignIdInt > 0){
      setLoading(true);
      campaignApi.get<Result<CampaignMsgList>>(`/campaign-msg/${campaignIdInt}/list`)
        .then(response => {
          const campaignData = response?.data?.result;
          setCampaignName(campaignData?.campaignName);
          setCsvData(campaignData.messages);
        })
        .catch(() => {
          toast.error('Failed to load campaign messages');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [campaignIdInt]);

  const validateData = (data: CampaignMessage[]): boolean => {
    let isValid = true;
    data.forEach((row, index) => {
      if (!row.msg || row.msg?.length < 5) {
        console.log(row.msg);
        toast.error(`Row ${index + 1}: Message is too short.`);
        isValid = false;
      }
      else if (row.msg?.length > 160) {
        toast.error(`Row ${index + 1}: Message exceeds 160 characters.`);
        isValid = false;
      }
      if (row.day < 0 || row.day > 365) {
        toast.error(`Row ${index + 1}: Day must be between 1 and 365.`);
        isValid = false;
      }
      if (row.hour < 1 || row.hour > 20) {
        toast.error(`Row ${index + 1}: Hour must be between 1 and 20.`);
        isValid = false;
      }
      if (row.minute < 0 || row.minute > 59) {
        toast.error(`Row ${index + 1}: Minute must be between 0 and 59.`);
        isValid = false;
      }
    });
    return isValid;
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      setFileError('No file selected');
      return;
    }

    Papa.parse(file, {
      header: true,
      complete: (results: { data: any }) => {
        const filteredData = results.data.filter((row: CampaignMessage) => {
          return Object.values(row).some((value) => value !== null && value !== '');
        });

        const parsedData: CampaignMessage[] = filteredData as CampaignMessage[];

        if (validateData(parsedData)) {
          setCsvData(parsedData);
          setFileError(null);
        } else {
          setCsvData([]);
        }
      },
      error: (error: { message: any }) => {
        setFileError(`Error parsing CSV: ${error.message}`);
      },
    });

  };

  const handleConfirm = () => {
    setConfirmOpen(true);
  };

  const handleCancel = () => {
    setConfirmOpen(false); // Close the confirm dialog if canceled
  };

  const handleConfirmSubmit = () => {
    setConfirmOpen(false); // Close the confirm dialog when confirmed
    handleSubmit();
  };

  const handleSubmit = () => {
    setLoading(true);
    if (csvData.length === 0) {
      toast.error('No valid data to submit');
      setLoading(false);
      return;
    }

    // Prepare JSON data
    const payload = csvData.map((message) => ({
      id: 0,
      campaignId: campaignIdInt,
      msgCode: message.msg,
      msg: message.msg,
      day: message.day,
      hour: message.hour,
      minute: message.minute,
      isQuestion: message.isQuestion ?? false,
      allowedAnswers: message.allowedAnswers ?? ''
    }));

    campaignApi.post(`/campaign-msg/bulk-save`, payload)
      .then(() => {toast.success('Messages uploaded successfully'); navigate('/admin/campaigns');})
      .catch(() => toast.error('Failed to upload messages'))
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      <DocumentTitle title={"Campaign - Bulk Upload Messages"} />
      <div>
        <h1>Bulk Upload Campaign Messages</h1>
        <a href='/admin/campaigns'>&lt;&lt; Back to Campaigns</a>
        <br/>
          <Message>Campaign: {campaignName}</Message>
        <div>
          <h3>Upload CSV</h3>
          <div>
            <h4>csv file format</h4>
            <ul>
            <li>header = YES</li>
            <li>columns: msgCode, msg, day, hour, minute, isQuestion, allowedAnswers</li>
           </ul>
          </div>
          <br/>
          <input type="file" accept=".csv" onChange={handleFileUpload} />
          {fileError && <div className="error">{fileError}</div>}
          {csvData?.length > 0 && (
            <div>
              <h4>Review Data</h4>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>#</Table.HeaderCell>
                    <Table.HeaderCell>Message Code</Table.HeaderCell>
                    <Table.HeaderCell>Message</Table.HeaderCell>
                    <Table.HeaderCell>Message Size (char)</Table.HeaderCell>
                    <Table.HeaderCell>Day</Table.HeaderCell>
                    <Table.HeaderCell>Hour</Table.HeaderCell>
                    <Table.HeaderCell>Minute</Table.HeaderCell>
                    <Table.HeaderCell>Is Question?</Table.HeaderCell>
                    <Table.HeaderCell>Allowed Ans</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {csvData.map((row, index) => (
                    <Table.Row key={index}>
                      <Table.Cell>{index + 1}</Table.Cell>
                      <Table.Cell>{row.msgCode}</Table.Cell>
                      <Table.Cell>{row.msg}</Table.Cell>
                      <Table.Cell>{row.msg?.length}</Table.Cell>
                      <Table.Cell>{row.day}</Table.Cell>
                      <Table.Cell>{row.hour}</Table.Cell>
                      <Table.Cell>{row.minute}</Table.Cell>
                      <Table.Cell>{row.isQuestion}</Table.Cell>
                      <Table.Cell>{row.allowedAnswers}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <Button primary onClick={handleConfirm} loading={loading} disabled={submitting}>
                Upload Campaign Messages
              </Button>

              <Confirm
                open={confirmOpen}
                onCancel={handleCancel}
                onConfirm={handleConfirmSubmit}
                content="All the current messages of the campaign would be erased and replaced with these uploaded messages. Continue?"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CampaignMessageBulkUpload;
