import React, { useState, useEffect } from 'react';
import { ScheduleCourseDto, WeekDay, InterLiftSchedule } from '../../models/interlift/interlift_schedule';
import { api } from "../../shared/axios_wrapper";
import { toast } from 'react-toastify';
//import { useNavigate } from 'react-router-dom';
import ScheduleForm from './schedule_form';
import ScheduleModal from './schedule_modal';
import { useAuth } from '../../app/context/auth_provider';
import { UserRole } from '../../models/user/user';
import { DocumentTitle } from '../../app/components/document_title';
import ScheduleTable from './schedule_table';
import ConfirmDeleteModal from './confirm_schedule_delete_modal';
import { Result } from '../../models/common/paged_result';
import { KeyValue } from '../../models/common/key_value';
import ConfirmButton from '../../app/components/confirm_button';
import { Button, Select } from 'semantic-ui-react';

function InterLiftScheduleForm() {
  //const maxAvailableSlots = 20;

  //const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [programName, setProgramName] = useState<string>('');
  const [weekBeginDate, setWeekBeginDate] = useState<string>('');
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [interLiftCourses, setInterLiftCourses] = useState<ScheduleCourseDto[]>([]);
  const [selCourseDto, setSelCourseDto] = useState<ScheduleCourseDto | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState<WeekDay | null>(null);
  const [selectedSlot, setSelectedSlot] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [schedulerResponse, setSchedulerResponse] = useState<KeyValue[]>([]);
  const [schedule, setSchedule] = useState<Map<WeekDay, Map<string, ScheduleCourseDto>>>(new Map());

  const interLiftScheduleCacheKey = "interlift_schedule";

  const initializeSchedule = (): Map<WeekDay, Map<string, ScheduleCourseDto>> => {

    const savedSchedule = localStorage.getItem(interLiftScheduleCacheKey);
    var schedule = new Map<WeekDay, Map<string, ScheduleCourseDto>>();
    if (savedSchedule) {
      try {
        // Parse and reconstruct the Map from JSON
        const parsedSchedule = JSON.parse(savedSchedule);
        Object.keys(parsedSchedule).forEach(day => {
          schedule.set(
            parseInt(day) as WeekDay,
            new Map(Object.entries(parsedSchedule[day]))
          );
        });

        const hasSchedules = Array.from(schedule.values()).some(daySchedule => daySchedule.size > 0);
        if (schedule.size > 0 && hasSchedules) {
          toast.info("Schedule loaded from memory. Please review before proceeding.", { theme: "colored" });
        }
      } catch (e) {
        console.error("Failed to parse schedule from localStorage", e);
      }
    }

    if (schedule.size === 0) {
      // Fallback to default logic if nothing is found in localStorage or parsing fails
      schedule = new Map<WeekDay, Map<string, ScheduleCourseDto>>();
      const days = Object.values(WeekDay).filter((day) => typeof day === 'number') as WeekDay[];

      days.forEach((day) => {
        schedule.set(day, new Map<string, ScheduleCourseDto>());
      });
    }
    return schedule;
  };

  const [previousSchedules, setPreviousSchedules] = useState<string[]>([]);
  const [showScheduleDropdown, setShowScheduleDropdown] = useState(false);
  const [selectedScheduleFile, setSelectedScheduleFile] = useState<string | null>(null);


  useEffect(() => {
    const initialSchedule = initializeSchedule();
    setSchedule(initialSchedule);
  }, []);

  useEffect(() => {
    const saveToLocalStorage = () => {
      try {
        // Check if there are any schedules in the Map
        const hasSchedules = Array.from(schedule.values()).some(daySchedule => daySchedule.size > 0);

        if (!hasSchedules) {
          // Clear localStorage if there are no schedules
          localStorage.removeItem(interLiftScheduleCacheKey);
          return;
        }

        // Convert the schedule Map to a plain object for JSON serialization
        const serializedSchedule: Record<string, Record<string, ScheduleCourseDto>> = {};
        schedule.forEach((daySchedule, day) => {
          serializedSchedule[day] = Object.fromEntries(daySchedule);
        });

        // Save the serialized object to localStorage
        localStorage.setItem(interLiftScheduleCacheKey, JSON.stringify(serializedSchedule));
      } catch (error) {
        console.error("Error saving schedule to localStorage", error);
      }
    };

    saveToLocalStorage();
  }, [schedule]);

  useEffect(() => {
    if ((user?.role === UserRole.SuperAdmin) || (user?.accountId === 6 && user?.role >= UserRole.LocationAdmin)) {
      setLoading(true);
      api.get<Result<ScheduleCourseDto[]>>('interlift/get-course-module-list')
        .then(r => {
          if (r?.data?.result) {
            setInterLiftCourses(r.data.result);
          }
        })
        .catch(() => {
          toast.error("Error fetching InterLift Courses", { theme: "colored" });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [user?.accountId, user?.role]);

  const handleAddSlot = (day: WeekDay, slot: string) => {
    setSelectedDay(day);
    setSelectedSlot(slot);
    setSelCourseDto(null); // Open modal with an empty slot
    setModalOpen(true);
  };


  const handleSave = (updatedCourse: ScheduleCourseDto) => {
    if (selectedDay && updatedCourse.slot) {
      setSchedule(prev => {
        const newSchedule = new Map(prev);
        const daySchedule = new Map(newSchedule.get(selectedDay) || new Map());

        // Store the course by the correct slot value (e.g., "Education Hour 2")
        daySchedule.set(updatedCourse.slot, updatedCourse);
        newSchedule.set(selectedDay, daySchedule);

        return newSchedule;
      });

      setModalOpen(false);
      setSelCourseDto(null);
    }
  };


  const handleEditLinkClick = (day: WeekDay, slot: string) => {
    console.log('day: ' + day + ' slot: ' + slot);

    const daySchedule = schedule.get(day);
    const courseModule = daySchedule?.get(slot) || null;

    if (courseModule) {
      setSelectedDay(day);
      setSelectedSlot(slot);
      setSelCourseDto(courseModule);
      setModalOpen(true);
    }
  };

  const handleDeleteLinkClick = (day: WeekDay, slot: string) => {
    setSelectedDay(day);
    setSelectedSlot(slot);
    setConfirmDeleteOpen(true);
  };

  const handleDelete = () => {
    if (selectedDay && selectedSlot) {
      setSchedule(prev => {
        const newSchedule = new Map(prev);
        const daySchedule = new Map(newSchedule.get(selectedDay) || new Map());
        daySchedule.delete(selectedSlot);
        newSchedule.set(selectedDay, daySchedule);
        return newSchedule;
      });
      setConfirmDeleteOpen(false);
    }
  };

  const isValidWeekDate = (date: string): boolean => {
    try {
      const selectedDate = new Date(`${date}T00:00:00Z`);
      const currentDate = new Date();
      currentDate.setUTCHours(0, 0, 0, 0);

      const oneYearFutureDate = new Date(currentDate);
      oneYearFutureDate.setUTCFullYear(currentDate.getUTCFullYear() + 1);

      const currentDayOfWeek = currentDate.getUTCDay();
      const startOfWeek = new Date(currentDate);
      startOfWeek.setUTCDate(currentDate.getUTCDate() - (currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1));

      const isWithinThisWeek = selectedDate >= startOfWeek && selectedDate <= currentDate;
      const isFutureDate = selectedDate > currentDate;
      const isWithinOneYear = selectedDate <= oneYearFutureDate;

      return (isWithinThisWeek || isFutureDate) && isWithinOneYear;
    } catch (error) {
      console.error('Error parsing date:', error);
      return false;
    }
  };

  const getJustDate = (date: string): Date => {
    const weekBeginDate = new Date(`${date}T00:00:00Z`);
    return weekBeginDate;
  }

  const handleSubmit = () => {
    setFormErrors([]);
    const errors: string[] = [];

    if (!programName || programName.trim() === "") {
      errors.push("Program Name is required.");
    }

    if (!isValidWeekDate(weekBeginDate)) {
      errors.push("Week Date must be a day in the current week or a date within a year from today");
    }

    if (errors.length > 0) {
      setFormErrors(errors);
      return;
    }

    //console.log(schedule);

    const courses: ScheduleCourseDto[] = [];
    schedule.forEach((daySchedule, day) => {
      daySchedule.forEach((course, slot) => {
        if (course.courseName) {
          courses.push({ ...course, day, slot });
        }
        else if (!course.interLiftSchedule) {
          courses.push({ ...course, day, slot });
        }
      });
    });

    if (courses.length === 0) {
      errors.push("Make sure to have at least one slot filled in");
    }

    if (errors.length > 0) {
      setFormErrors(errors);
      return;
    }

    const interLiftSchedule: InterLiftSchedule = {
      programName,
      weekBeginDate: getJustDate(weekBeginDate),
      courses,
    };

    //console.log(interLiftSchedule);

    setSubmitting(true);
    api.post<Result<KeyValue[]>>('interlift/generate-schedule-package', interLiftSchedule)
      .then(r => {
        //console.log(r);
        if (r?.data?.result) {
          //console.log(r?.data?.result);
          setSchedulerResponse(r?.data?.result);
          toast.success("Schedule package generated successfully!");
          //navigate('/admin');
        }
      })
      .catch(() => {
        toast.error("Error submitting schedule.", { theme: "colored" });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const fetchPreviousSchedules = () => {
    api.get<string[]>('interlift/get-saved-schedule-json-list')
      .then(res => {
        if (res?.data?.length) {
          setPreviousSchedules(res.data);
          setShowScheduleDropdown(true);
        } else {
          toast.info("No previous schedules found.", { theme: "colored" });
        }
      })
      .catch(() => {
        toast.error("Failed to load previous schedules.", { theme: "colored" });
      });
  };

  const loadScheduleFromFile = (filename: string) => {
    api.get<InterLiftSchedule>(`interlift/get-saved-schedule-json-data/${filename}`)
      .then(res => {
        if (res?.data?.courses) {
          const newSchedule = new Map<WeekDay, Map<string, ScheduleCourseDto>>();
          const days = Object.values(WeekDay).filter(d => typeof d === 'number') as WeekDay[];
          days.forEach(day => newSchedule.set(day, new Map()));

          res.data.courses.forEach(course => {
            const dayMap = newSchedule.get(course.day) || new Map();
            dayMap.set(course.slot, course);
            newSchedule.set(course.day, dayMap);
          });

          setProgramName(res.data.programName);
          setWeekBeginDate(res.data.weekBeginDate.toString().substring(0, 10));
          setSchedule(newSchedule);
          toast.success("Schedule loaded successfully", { theme: "colored" });
          setShowScheduleDropdown(false);
          setSelectedScheduleFile(null);
        }
      })
      .catch(() => {
        toast.error("Failed to load schedule data", { theme: "colored" });
      });
  };

  const confirmLoadSchedule = (file: string) => {
    if (window.confirm(`Load schedule from ${file}? This will overwrite your current schedule.`)) {
      loadScheduleFromFile(file);
    }
  };

  return (
    <>
      <DocumentTitle title="interLIFT Worksheet Compiler" />
      <h2>interLIFT Worksheet Compiler</h2>

      {schedulerResponse?.length > 0 ? (
        <div>
          <h3>Scheduled Package is created with the following files:</h3>
          <ul>
            {schedulerResponse?.map((item, index) => (
              <li key={index}>
                <a
                  href={item.value}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.key}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div>
          {loading ? (
            <div className="loading-indicator">
              <p>Loading...</p>
            </div>
          ) : (
            <>
              {formErrors.length > 0 && (
                <div className="error-messages">
                  {formErrors.map((error, index) => (
                    <p key={index} style={{ color: 'red' }}>{error}</p>
                  ))}
                </div>
              )}

              <ScheduleForm
                programName={programName}
                weekBeginDate={weekBeginDate}
                submitting={loading || submitting}
                handleSubmit={handleSubmit}
                handleProgramNameChange={(e) => setProgramName(e.target.value)}
                handleDateChange={(e) => setWeekBeginDate(e.target.value)}
              />

              <ConfirmButton
                disabled={!Array.from(schedule.values()).some(daySchedule => daySchedule.size > 0)}
                id={0} value={" Clear Data"} confirmButtonText={"Clear Data"} color={"grey"}
                content={`Do you want to Delete all slots in the schedule?`}
                iconName="calendar alternate outline"
                handleConfirm={() => { localStorage.removeItem(interLiftScheduleCacheKey); setSchedule(initializeSchedule()); }} />
                &nbsp;&nbsp;
              <Button onClick={fetchPreviousSchedules} style={{ marginLeft: '1rem' }} size='large'>
                  Load Previous Schedules
                </Button>

              {showScheduleDropdown && (
                <div style={{ marginTop: '10px', width:"100%" }}>
                  <label>Select a saved schedule:&nbsp;</label> <br/>
                  <Select
                    placeholder="Select a schedule"
                    value={selectedScheduleFile || ''}
                    onChange={(e, data) => {
                      const file = data.value as string;
                      setSelectedScheduleFile(file);
                      confirmLoadSchedule(file);
                    }}
                    options={previousSchedules?.map((file) => ({
                      key: file,
                      value: file,
                      text: file?.replace(".json", ""),
                    }))}
                  />
                </div>
              )}

              <div className='pt-4'>
                <br/>
                You can double-click to add or edit a schedule in the selected day or time slot.</div>
              <br />
              <ScheduleTable
                days={Array.from(schedule.keys())}
                schedule={schedule}
                handleEditLinkClick={handleEditLinkClick}
                handleDeleteLinkClick={handleDeleteLinkClick}
                handleAddSlot={handleAddSlot}
              />

              {modalOpen && (
                <ScheduleModal
                  open={modalOpen}
                  slot={selCourseDto || null}
                  isEditing={!!selCourseDto}
                  onSave={handleSave}
                  onClose={() => setModalOpen(false)}
                  existingSlots={Array.from(schedule.values())
                    .flatMap(dayMap => Array.isArray(dayMap) ? dayMap : Array.from(dayMap.values()))}
                  courseModules={interLiftCourses}
                  selectedDay={selectedDay}
                  selectedSlot={selectedSlot}
                />
              )}

              {confirmDeleteOpen && (
                <ConfirmDeleteModal
                  confirmDeleteOpen={confirmDeleteOpen}
                  setConfirmDeleteOpen={setConfirmDeleteOpen}
                  handleConfirmDelete={handleDelete}
                />
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}

export default InterLiftScheduleForm;
