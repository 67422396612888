import { List } from "semantic-ui-react";
import { DocumentTitle } from "../../app/components/document_title";

function AdminPage() {

    return (<>
        <DocumentTitle title="Admin"/>
        <h3>Admin</h3>
        <List divided relaxed>
            <List.Item style={{padding:"20px"}}>
                <List.Icon name='play circle outline' size='large' verticalAlign='middle' />
                <List.Content>
                    <List.Header><a href="/admin/modules">Learning Modules Management</a></List.Header>
                    <List.Description></List.Description>
                </List.Content>
            </List.Item>
            <List.Item style={{padding:"20px", paddingLeft: "10px"}}>
                <List.Icon name='list ul' size='large' verticalAlign='middle' />
                <List.Content>
                    <List.Header><a href="/admin/courses">Courses Management</a></List.Header>
                    <List.Description></List.Description>
                </List.Content>
            </List.Item>
            <List.Item style={{padding:"20px"}}>
                <List.Icon name='at' size='large' verticalAlign='middle' />
                <List.Content>
                    <List.Header><a href="/accounts">Account Management</a></List.Header>
                    <List.Description></List.Description>
                </List.Content>
            </List.Item>
            <List.Item style={{padding:"20px", paddingLeft: "10px"}}>
                <List.Icon name='list ul' size='large' verticalAlign='middle' />
                <List.Icon name='at' size='large' verticalAlign='middle' />
                <List.Content>
                    <List.Header><a href="/account/account-courses">Account Courses Management</a></List.Header>
                    <List.Description></List.Description>
                </List.Content>
            </List.Item>
            <List.Item style={{padding:"20px"}}>
                <List.Icon name='map pin' size='large' verticalAlign='middle' />
                <List.Content>
                    <List.Header><a href="/locations">Location Management</a></List.Header>
                    <List.Description></List.Description>
                </List.Content>
            </List.Item>
            <List.Item style={{padding:"20px", paddingLeft: "10px"}}>
                <List.Icon name='list ul' size='large' verticalAlign='middle' />
                <List.Icon name='map pin' size='large' verticalAlign='middle' />
                <List.Content>
                    <List.Header><a href="/location/location-courses">Location Courses Management</a></List.Header>
                    <List.Description></List.Description>
                </List.Content>
            </List.Item>
            <List.Item style={{padding:"20px"}}>
                <List.Icon name='users' size='large' verticalAlign='middle' />
                <List.Content>
                    <List.Header><a href="/location/users">Users Management</a></List.Header>
                    <List.Description></List.Description>
                </List.Content>
            </List.Item>

            <List.Item style={{padding:"20px"}}>
                <List.Icon name='podcast' size='large' verticalAlign='middle' />
                <List.Content>
                    <List.Header><a href="/admin/interlift/schedule-package">interLIFT Worksheet Compiler</a></List.Header>
                    <List.Description></List.Description>
                </List.Content>
            </List.Item>

            <List.Item style={{padding:"20px"}}>
                <List.Icon name='bullseye' size='large' verticalAlign='middle' />
                <List.Content>
                    <List.Header><a href="/admin/campaigns">NudgeBug Campaigns</a></List.Header>
                    <List.Description></List.Description>
                </List.Content>
            </List.Item>
        </List>
    </>);

}
export default AdminPage