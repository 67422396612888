import { Divider, Icon, List } from "semantic-ui-react";
import { CourseModuleViewDto } from "../../../models/course/learning_module";
import ExpandableSegment from "../../../app/components/expandable_segment";
import { useAuth } from "../../../app/context/auth_provider";
import { UserRole } from "../../../models/user/user";

interface props {
  module: CourseModuleViewDto | undefined,
  trackLessonUserAction?: any
}
export const ReadingMaterial = ({ module, trackLessonUserAction }: props) => {
  const { user } = useAuth();
  if ((!module?.materials || module?.materials.length === 0) &&
  (!module?.instructorMaterials || module?.instructorMaterials.length === 0)){
    return (<></>);
  }
  return (
    <>
      <Divider horizontal>Reading Material {module?.materials.length > 0 ? "(" + module?.materials.length + ")" : ""}</Divider>
      <ExpandableSegment title="How to use reading materials?" content={
        <p>
          Reading materials, including video transcripts, will open in a new browser tab. This tab will remain open.
        </p>
      } />

      <List selection relaxed size="large">
        {module?.materials?.map((m, index) => (
          <List.Item key={"material_" + index} style={{ color: "black" }}>
            <br />
            <List.Content>
              {index + 1}.  <Icon name="file pdf" /><a href={m.value} target="_material">{m.key}</a>
            </List.Content>
            <br />
          </List.Item>
        ))}
      </List>

      {user?.role !== UserRole.Student && module?.instructorMaterials?.length > 0 &&
        <Divider horizontal>Instructor Material {module?.instructorMaterials.length > 0 ?
          "(" + module?.instructorMaterials.length + ")" : ""}</Divider>}

      {user?.role !== UserRole.Student && <List selection relaxed size="large">
        {module?.instructorMaterials?.map((m, index) => (
          <List.Item key={"instructor_material_" + index} style={{ color: "black" }}>
            <br />
            <List.Content>
              {index + 1}.  <Icon name="file pdf" /><a href={m.value} target="_material">{m.key}</a>
            </List.Content>
            <br />
          </List.Item>
        ))}
      </List>}

    </>)
}