
import React, { useEffect, useState } from 'react';
import { Table, Button, Icon, Message, Modal } from 'semantic-ui-react';
import { useNavigate, useParams } from 'react-router-dom';
import { campaignApi } from '../../shared/axios_wrapper';
import { CampaignUser, CampaignUserListDto } from '../../models/campaign/campaign';
import { DocumentTitle } from '../../app/components/document_title';
import { formatDate } from '../../shared/utils';
import { Result } from '../../models/common/paged_result';

const CampaignUserList = () => {
  const { campaignId } = useParams<{ campaignId: string }>();
  const campaignIdInt = parseInt(campaignId ?? '0');
  const [campaignName, setCampaignName] = useState<string>('');
  const [campaignUsers, setCampaignUsers] = useState<CampaignUser[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<CampaignUser | null>(null);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    if (campaignIdInt > 0) {
      setLoading(true);
      campaignApi.get<Result<CampaignUserListDto>>(`/campaign-user/list/${campaignIdInt}`)
        .then(response => {
          setCampaignName(response?.data?.result?.campaignName);
          setCampaignUsers(response.data?.result?.users);
        })
        .catch(() => {
          alert('Failed to load users');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [campaignIdInt]);

  const handleDeleteUser = (user: CampaignUser) => {
    setUserToDelete(user);
    setDeleteModalOpen(true);
  };

  const confirmDeleteUser = () => {
    if (userToDelete) {
      setDeleting(true);
      campaignApi.del(`/campaign-action/${campaignIdInt}/user/delete/${userToDelete.id}`)
        .then(() => {
          setCampaignUsers(campaignUsers.filter(user => user.id !== userToDelete.id));
          setDeleteModalOpen(false);
          setUserToDelete(null);
        })
        .catch(() => {
          alert('Failed to delete campaign user');
        })
        .finally(() => {
          setDeleting(false);
        });
    }
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setUserToDelete(null);
  };

  return (
    <>
    <DocumentTitle title={"Campaign Users"} />
    <div>
      <a href='/admin/campaigns'>&lt;&lt; Back to Campaigns</a>
      <h1>Campaign Users</h1>
      <Button primary onClick={() => navigate(`/admin/campaign-users/edit/${campaignIdInt}/0`)}>
        <Icon name='add' /> Add Campaign User
      </Button>
      <br/>
      <Message>Campaign: {campaignName}</Message>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>User Name</Table.HeaderCell>
            <Table.HeaderCell>Phone</Table.HeaderCell>
            <Table.HeaderCell>Opt In Date</Table.HeaderCell>
            <Table.HeaderCell>Opt Out Date</Table.HeaderCell>
            <Table.HeaderCell>Completed Date</Table.HeaderCell>
            <Table.HeaderCell>Active?</Table.HeaderCell>
            <Table.HeaderCell>Msg. Received / Total</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {campaignUsers?.map(user => (
            <Table.Row key={user.id}>
              <Table.Cell>{user.firstName} {user?.lastName}</Table.Cell>
              <Table.Cell>{user.userPhone}</Table.Cell>
              <Table.Cell>{formatDate(user.optInDate)}</Table.Cell>
              <Table.Cell>{formatDate(user.optOutDate)}</Table.Cell>
              <Table.Cell>{formatDate(user.completedDate)}</Table.Cell>
              <Table.Cell textAlign='center'>{user.active ? 'Yes' : 'No'}</Table.Cell>
              <Table.Cell textAlign='center'>{user.msgReceivedCount} / {user.totalMsgCount}</Table.Cell>
              <Table.Cell>
              <Button icon onClick={() => navigate(`/admin/campaign-user-msg-report/${campaignIdInt}/${user.id}`)}>
                  <Icon name='list' /> User Msg Report
                </Button>
                <Button icon onClick={() => navigate(`/admin/campaign-users/edit/${campaignIdInt}/${user.id}`)}>
                  <Icon name='edit' />
                </Button>
                <Button icon onClick={() => handleDeleteUser(user)}>
                    <Icon name='delete' color='red'/>
                  </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {loading && <p>Loading...</p>}
    </div>
     {/* Delete Confirmation Modal */}
     <Modal size='tiny' open={deleteModalOpen} onClose={closeDeleteModal}>
        <Modal.Header>Delete Campaign User</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete the selected Campaign User <strong>{userToDelete?.firstName} {userToDelete?.lastName} - {userToDelete?.userPhone} </strong> - and all the user messages?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeDeleteModal} disabled={deleting}>
            Cancel
          </Button>
          <Button negative onClick={confirmDeleteUser} loading={deleting} disabled={deleting}>
            Delete
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default CampaignUserList;
