import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { UserRole } from '../../models/user/user';
import { useAuth } from '../context/auth_provider';
import { useEffect, useState } from 'react';

function ProtectedRoute({ element: Element, allowedRole,...args }: any) {
  const { isAuthenticated, user} = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated && user) {
      setIsLoading(false); // User data is available
    }
  }, [isAuthenticated, user]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  //const loginWithRedirect = `/login?redirect=${location.pathname}`;
  const loginWithRedirect =
    location.pathname !== '/login' ? `/login?redirect=${location.pathname}` : '/login';
  if (!isAuthenticated) {
    return <Navigate to={loginWithRedirect} replace />;
  }

  const userRole = user?.role ?? UserRole.Unknown;
  const isAuthorized = userRole >= allowedRole;

  if (!isAuthorized) {
    return <Navigate to="/unauthorized" />;
  }

  return <Outlet />;
}

export default ProtectedRoute;
