import React, { useCallback, useEffect, useState } from "react";
import { Accordion, Button, Icon, Label, List, Popup, Image} from "semantic-ui-react";
import { useBlocker, useNavigate, useParams } from "react-router-dom";
import { api } from "../../shared/axios_wrapper";
import { Result } from "../../models/common/paged_result";
import { toast } from "react-toastify";
import { DocumentTitle } from "../../app/components/document_title";
//import { formatDate } from "../../shared/utils";
import { ModuleTaskOutput, QuestionType, UserCourseModuleTaskOutputDto } from "../../models/user/user_lesson_action";
import { useAuth } from "../../app/context/auth_provider";
import ExpandableSegment from "../../app/components/expandable_segment";
import { UserRole } from "../../models/user/user";

function LessonModuleTasks() {
  const navigate = useNavigate();
  const {user} = useAuth();
  const { courseId, moduleCode } = useParams();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [module, setModule] = useState<UserCourseModuleTaskOutputDto | undefined>();
  //const [moduleCompleted, setModuleCompleted] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [activeNestedIndex, setActiveNestedIndex] = useState<number | null>(null);
  const [userResponses, setUserResponses] = useState<ModuleTaskOutput[]>([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [imgNo, setImgNo] = useState<number | null>(null);

  useBlocker(({ currentLocation, nextLocation }) => {
    if ((user?.role ?? 1) !== UserRole.Student) return false;
    const shouldBlock = hasChanges && currentLocation.pathname !== nextLocation.pathname;
    if (shouldBlock) {
      //console.log('Navigation blocked:', currentLocation.pathname, '->', nextLocation.pathname);
      saveUserTaskResponses();
      return false;
    }
    return shouldBlock;
  });

  useEffect(() => {
    const randomNo = Math.floor(Math.random() * (5 - 1 + 1)) + 1;
    setImgNo(randomNo);
  }, []);

  useEffect(() => {
    setLoading(true);
    const url = `account/${user?.accountId}/location/${user?.locationId}/lesson/course/${courseId}/module/${moduleCode}/tasks`;
    api.get<Result<UserCourseModuleTaskOutputDto>>(url)
      .then((r) => {
        if (r.data?.result) {
          setModule(r.data.result);
          //setModuleCompleted(r.data.result?.userModuleQuiz?.completedFlag ?? false);
          setUserResponses((prevResponses) => {
            const updatedResponses = [...prevResponses];

            // Assuming the API response structure has a tasks array
            const apiTasks = r.data.result.moduleTasks;

            apiTasks.forEach((apiTask, moduleIndex) => {
              // Check if the moduleIndex already exists in userResponses
              if (!updatedResponses[moduleIndex]) {
                updatedResponses[moduleIndex] = { assessment: {}, tasks: [] };
              }

              // Update or append tasks for the module
              apiTask.tasks.forEach((apiTaskItem, taskIndex) => {
                const existingTask = updatedResponses[moduleIndex].tasks[taskIndex];

                if (existingTask) {
                  // Update existing task
                  existingTask.ans = apiTaskItem.ans;
                  existingTask.selectedOptions =  apiTaskItem.selectedOptions?.map((option) => parseInt(option, 10));
                } else {
                  // Append new task
                  updatedResponses[moduleIndex].tasks[taskIndex] = {
                    taskName: apiTaskItem.taskName,
                    group: apiTaskItem.group,
                    type: apiTaskItem.type,
                    order: apiTaskItem.order,
                    row: apiTaskItem.row,
                    column: apiTaskItem.column,
                    ans: apiTaskItem.ans,
                    selectedOptions: apiTaskItem.selectedOptions?.map(option => parseInt(option, 10)),
                  };
                }
              });

              // Update or append assessment for the module
              updatedResponses[moduleIndex].assessment = {
                moduleCode: apiTask.assessment.moduleCode,
                taskName: apiTask.assessment.taskName,
                order: apiTask.assessment.order,
                ans: apiTask.assessment.ans,
              };
            });

            return updatedResponses;
          });
        }

        //if (!r.data.result?.userModuleQuiz?.completedFlag) {
        // Handle incomplete module logic if needed
        //}
      })
      .catch((e) => { toast.error("Error", { theme: "colored" }) })
      .finally(() => setLoading(false));
  }, [user?.accountId, user?.locationId, courseId, moduleCode]);


  const handleChange = (
    moduleIndex: number,
    taskIndex: number,
    optionIndex: number | undefined,
    answer: string | number | boolean | undefined,
    isAssessment: boolean = false
  ) => {
    setUserResponses((prevResponses) => {
      const updatedResponses = [...prevResponses];

      // Ensure the module index exists
      if (!updatedResponses[moduleIndex]) {
        updatedResponses[moduleIndex] = { assessment: {}, tasks: [] };
      }

      if (isAssessment) {
        // For assessment question
        updatedResponses[moduleIndex].assessment = {
          moduleCode: module?.moduleTasks[moduleIndex].assessment.moduleCode,
          taskName: module?.moduleTasks[moduleIndex].assessment.taskName,
          order: module?.moduleTasks[moduleIndex].assessment.order,
          ans: answer as boolean,
        };
      } else if (optionIndex !== undefined) {
        // For checkboxes and options with indices
        const selectedOptions =
          updatedResponses[moduleIndex].tasks[taskIndex]?.selectedOptions || [];

        if (answer === true) {
          // Add the option to the selected options
          selectedOptions.push(optionIndex);
        } else {
          // Remove the option from the selected options
          const index = selectedOptions.indexOf(optionIndex);
          if (index !== -1) {
            selectedOptions.splice(index, 1);
          }
        }

        // Update the state with the selected options
        updatedResponses[moduleIndex].tasks[taskIndex] = {
          taskName: module?.moduleTasks[moduleIndex].tasks[taskIndex].taskName,
          group: module?.moduleTasks[moduleIndex].tasks[taskIndex].group,
          type: module?.moduleTasks[moduleIndex].tasks[taskIndex].type,
          order: module?.moduleTasks[moduleIndex].tasks[taskIndex].order,
          row: module?.moduleTasks[moduleIndex].tasks[taskIndex].row,
          column: module?.moduleTasks[moduleIndex].tasks[taskIndex].column,
          ans: undefined,
          selectedOptions: selectedOptions
        };

      } else {
        // For text-based inputs and radio buttons
        updatedResponses[moduleIndex].tasks[taskIndex] = {
          taskName: module?.moduleTasks[moduleIndex].tasks[taskIndex].taskName,
          group: module?.moduleTasks[moduleIndex].tasks[taskIndex].group,
          type: module?.moduleTasks[moduleIndex].tasks[taskIndex].type,
          order: module?.moduleTasks[moduleIndex].tasks[taskIndex].order,
          row: module?.moduleTasks[moduleIndex].tasks[taskIndex].row,
          column: module?.moduleTasks[moduleIndex].tasks[taskIndex].column,
          ans: answer,
          selectedOptions: undefined
        };
      }

      setHasChanges(true);

      //console.log(updatedResponses);
      return updatedResponses;
    });
  };

  const renderTaskContent = (parentIndex: number, task: any, taskIndex: number) => {
    switch (task.questionType) {
      case QuestionType.SingleLineText:
        return (
          <>
          <input
            key={`input_${parentIndex}_${taskIndex}`}
            name={`input_${parentIndex}_${taskIndex}`}
            placeholder="Enter your answer"
            style={{ fontSize: "24px", lineHeight: "1.75", width: "100%" }}
            maxLength={100}
            defaultValue={task?.ans}
            onChange={(e) => handleChange(parentIndex, taskIndex, undefined, e.target.value)}
          />
          <br/>
          <Label size="large">Allows up to 100 characters</Label>
          </>
        );

      case QuestionType.NumberText:
        return (
          <>
          <input
            type="number"
            key={`input_${parentIndex}_${taskIndex}`}
            name={`input_${parentIndex}_${taskIndex}`}
            placeholder="Enter your answer"
            style={{ fontSize: "24px", lineHeight: "1.75", minWidth: "30%", width: "50%" }}
            maxLength={10}
            defaultValue={task?.ans}
            onChange={(e) => handleChange(parentIndex, taskIndex, undefined, e.target.value)}
          /> <br/>
          <Label size="large">Allows only numbers</Label>
          </>
        );
        case QuestionType.MultiLineText:
          return (
            <>
            <textarea
              key={`textarea_${parentIndex}_${taskIndex}`}
              name={`textarea_${parentIndex}_${taskIndex}`}
              placeholder="Enter your answer"
              rows={3}
              style={{ fontSize: "20px", width: "100%", lineHeight: "1.75" }}
              maxLength={500}
              defaultValue={task?.ans}
              onChange={(e) => handleChange(parentIndex, taskIndex, undefined, e.target.value)}
            /> <br/>
            <Label size="large">Allows up to 500 characters</Label>
            </>
          );
          case QuestionType.Check:
            return (
              <>
              <label key={`label_${parentIndex}_${taskIndex}`} style={{ fontSize: "20px" }}>
                <input
                  type="checkbox"
                  style={{ fontSize: "20px" }}
                  name={`check_${parentIndex}_${taskIndex}`}
                  defaultChecked={task.ans?.trim().toLowerCase() === 'true' ? true: false ?? false}
                  onChange={(e) => handleChange(parentIndex, taskIndex, undefined, e.target.checked)}
                />
                &nbsp;&nbsp;{task.options}
              </label><br/>
              <Label size="large">Select (check) if your answer is Yes to this question.</Label>
              </>
            );
      case QuestionType.CheckList:
        return (
          <div>
            {task.options.map((option: string, optionIndex: number) => (
              <div key={`checklist_${parentIndex}_${taskIndex}_${optionIndex}`}>
                <label style={{ fontSize: "20px" }}>
                  <input
                    type="checkbox"
                    name={`checkbox_${parentIndex}_${taskIndex}_${optionIndex}`}
                    defaultChecked={task?.selectedOptions?.includes(optionIndex)}
                    onChange={(e) =>
                      handleChange(parentIndex, taskIndex, optionIndex, e.target.checked)
                    }
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;{option}
                </label>
                <br />
                <br />
              </div>
            ))}
            <br/>
            <Label size="large">Select one or more options as applicable.</Label>
          </div>
        );
      case QuestionType.RadioList:
        return (
          <div>
            {task.options.map((option: string, optionIndex: number) => (
              <div key={`radio_${parentIndex}_${taskIndex}_${optionIndex}`}>
                <label style={{ fontSize: "20px" }}>
                  <input
                    type="radio"
                    name={`radio_${parentIndex}_${taskIndex}`}
                    defaultChecked={task?.selectedOptions?.includes(optionIndex)}
                    onChange={() =>
                      handleChange(parentIndex, taskIndex, optionIndex, true)
                    }
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;{option}
                </label>
                <br />
                <br />
              </div>
            ))}
            <br/>
            <Label size="large">Select only one option as applicable.</Label>
          </div>
        );
      default:
        return null;
    }
  };

  const renderTasks = (parentIndex: number, tasks: any[] | undefined, taskParentIndex: number) => {
    return (
      <List divided relaxed size="large" selection>
        {tasks?.map((task, taskIndex) => (
          task?.question && (
            <List.Item key={`task_item_${parentIndex}_${taskIndex}`}>
              <List.Icon name="question" size="large" verticalAlign="middle" style={{ color: "#3498db" }} />
              <List.Content>
                <List.Header as="h3" style={{ fontSize: "20px" }}>

                  {task.hasHtml === "1" && <div
                  dangerouslySetInnerHTML={{
                    __html:  `Q ${taskIndex + 1}. ${task.question}`
                  }} />
                }

                {task.hasHtml === "" && <div>Q {taskIndex + 1}. {task.question}</div>}

                </List.Header>
                <List.Description>
                  <br />
                  {renderTaskContent(parentIndex, task, taskIndex)}
                </List.Description>
              </List.Content>
            </List.Item>
          )
        ))}
      </List>
    );
  }

  const renderChecklistItems = (parentIndex: number, checklist: any[]) => {
    return (
      <List size="large" ordered>
        {checklist.map((item) => (
          <List.Item key={parentIndex + "_checklist_" + item.question} style={{ lineHeight: "1.5", fontSize: "20px" }}>
          <div
                  dangerouslySetInnerHTML={{
                    __html:  ` ${item.question}`
                  }} />
          </List.Item>
        ))}
      </List>
    );
  };

  const getDifficultyLevel = (difficultyLevel: string) => {
    if (difficultyLevel === "Simple") return "grey";
    if (difficultyLevel === "Average") return "grey";
    if (difficultyLevel === "Complex") return "grey";
  }

  const handleClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
    setActiveNestedIndex(-1);
  };

  const handleNestedClick = (nestedIndex: number) => {
    setActiveNestedIndex(activeNestedIndex === nestedIndex ? null : nestedIndex);
  };

  const renderAccordions = () => {
    if (module && module.moduleTasks) {
      return module.moduleTasks.map((level1, index) => (
        <Accordion key={index} styled fluid>
          <Accordion.Title
            active={activeIndex === index}
            index={index}
            style={{ color: activeIndex === index ? "#3498db" : "" }}
            onClick={() => handleClick(index)}
          >

            <h2> <Icon name='dropdown' />
              <Icon name='hand point right outline' /> Task {index + 1}: {level1.assessment.taskName}
              &nbsp;&nbsp;
              <Label tag color={getDifficultyLevel(level1.assessment.difficultyLevel)} style={{ marginTop: "-10px" }}>
                {level1.assessment.difficultyLevel}
              </Label>
            </h2>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === index} style={{ backgroundColor: activeIndex === index ? "#CEDAEC" : ""}}>

            <Accordion styled fluid>
              <Accordion.Title
                active={activeIndex === index && activeNestedIndex === 1}
                index={1}
                onClick={() => handleNestedClick(1)}
              >
                <h3 style={{ fontSize: "24px", color: "#3498db" }}> <Icon name='dropdown' /> <Icon name='tasks' /> Checklist</h3>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === index && activeNestedIndex === 1}>
                {level1.checkList && renderChecklistItems(index, level1.checkList)}
              </Accordion.Content>
            </Accordion>

            <Accordion styled fluid>
              <Accordion.Title
                active={activeIndex === index && activeNestedIndex === 2}
                index={2}
                onClick={() => handleNestedClick(2)}
              >
                <h3 style={{ fontSize: "24px", color: "#3498db" }}><Icon name='dropdown' /> <Icon name='question circle outline' /> My Details</h3>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === index && activeNestedIndex === 2}>
                <div>
                {level1.tasks && renderTasks(index, level1.tasks, index)}
                </div>
              </Accordion.Content>
            </Accordion>

            <div style={{ fontSize: "20px", paddingTop:"6px" }}>
            <h3 style={{ color: "#bd632f" }}>
              &nbsp;&nbsp;&nbsp;<Icon name='exclamation triangle' />&nbsp; Latest Assessment: {level1.assessment.question}
            </h3>
            <label style={{ paddingLeft: "30px", lineHeight: "1.5" }}>
              <input
                type='radio'
                id={`assessment_${index}_yes`}
                name={`assessment_${index}`}
                defaultChecked={(module.moduleTasks[index].assessment.ans === true ? true: false ?? false)}
                onChange={() => handleChange(index, 0, undefined, true, true)}
              />
              &nbsp;&nbsp;Yes, I agree
            </label>
            <br />
            <label style={{ paddingLeft: "30px", lineHeight: "1.5" }}>
              <input
                type='radio'
                id={`assessment_${index}_no`}
                name={`assessment_${index}`}
                defaultChecked={(module.moduleTasks[index].assessment.ans === false ? true: false ?? false)}
                onChange={() => handleChange(index, 0, undefined, false,true)}
              />
              &nbsp;&nbsp;No, I disagree
            </label>
            <br /><br />
            <Label size='large'>
            Please make regular updates to the Latest Assessment question. This will help you flag unresolved issues.
            </Label>
          </div>
            {level1.assessment.hint &&
              <Popup
                trigger={<Button icon='question circle outline'
                          content="Helpful Tip" style={{ color: "#3498db", marginTop: "6px" }}
                        />}>
                <div dangerouslySetInnerHTML={{ __html: level1.assessment.hint}}></div>
              </Popup>
            }
          </Accordion.Content>
        </Accordion>
      ));
    }
  };

  const saveUserTaskResponses = useCallback(async () => {
    if (!hasChanges) return;
    if ((user?.role ?? 1) !== UserRole.Student) return;
    console.log('....save user tasks');
    setHasChanges(false);
    try {
      const url = 'lesson/save-user-task-results';
      await api.post<Result<number>>(url, {
        accountId: user?.accountId,
        locationId: user?.locationId,
        userId: user?.id,
        courseId: module?.module.courseId ?? 0,
        moduleId: module?.module.moduleId ?? 0,
        moduleCode: module?.module.code ?? '',
        todoListJson: userResponses
      });

      toast.success("Task data has been saved", {
          theme: "colored",
      });
  } catch (error) {
      console.error("Save error:", error);
      toast.error("Error saving task data", {
          theme: "colored",
      });
  }
  },[hasChanges,user, module?.module, userResponses]);

  const handleSubmit = async () => {
    if (!hasChanges) return;

    setSubmitting(true);
    setHasChanges(false);
    //const jsonData = JSON.stringify(userResponses);

    await saveUserTaskResponses();

    setSubmitting(false);
    navigate(`/lessons/courses/${courseId}`);

  };

  useEffect(() => {
    if ((user?.role ?? 1) === UserRole.Student) {
        const autoSaveInMin = 2;
        const saveUserResponsesTimeout = setTimeout(saveUserTaskResponses, 1000 * 60 * autoSaveInMin);

        return () => clearTimeout(saveUserResponsesTimeout);
    }
  }, [userResponses, hasChanges, user, courseId, saveUserTaskResponses]);

  return (
    <div>
      <DocumentTitle title={"Lesson Module Tasks"} />

      {!loading && (
        <Button basic onClick={() => {navigate(`/lessons/courses/${courseId}`)}}>
          <Icon name="arrow left" size="big" /> Back to Course
        </Button>
      )}

      {!loading && module && (
        <>
          <h2>
            <Icon name='play circle outline' /> Module: {module.module?.name}
          </h2>
          <div>Course: {module?.module?.courseName} &nbsp; | &nbsp;
            {/* <Icon name="clock outline" />
            Last accessed on: {formatDate(module?.?.lastModuleAccessedDate)} */}
          </div>

          <ExpandableSegment initialExpand={true} title="Task Completion Instructions" content={
         <>
         <Image  src={`/assets/reentry/b${imgNo}.png`}  style={{width:"150px", paddingRight: "20px"}} floated="right" inline/>
          <ul style={{fontSize: "20px", lineHeight:"1.2"}}>
            <li> Review Checklists and take action or get help if you need it.</li>
            <li>Fill in My Details as able (updating over time).</li>
            <li>Answer the Latest Assessment after you have made all possible progress.</li>
            <li>View hints if available and save your progress as you go!</li>
          </ul>

          <p>If you have questions or encounter issues, seek assistance.</p>
          <br/>
         </>
        } />
        </>
      )}
      <br />
      <br/>
        {!loading && <Label size='large'>
          <Icon name='save' size='large'/>
          &nbsp;Click "Save My Details" button from time to time at the top or bottom of the page
        </Label>}
      <br/><br/>

      {!loading && (user?.role ?? 1) === UserRole.Student &&
      <>
      <Button primary
      disabled={submitting}
      content='Save My Details' floated="right"
      size="huge"
      onClick={handleSubmit} />
       <br /><br/>
       </>
      }
      <br />

      {!loading && renderAccordions()}
      <br />
      {!loading && (user?.role ?? 1) === UserRole.Student && <Button primary
      disabled={submitting}
      content='Save My Details' floated="right"
      size="huge"
      onClick={handleSubmit} />}
      <br /><br />
    </div>
  );
}

export default LessonModuleTasks;
