
import React, { useEffect, useState } from 'react';
import { Table, Button, Icon, Modal } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { Campaign } from '../../models/campaign/campaign';
import { campaignApi } from '../../shared/axios_wrapper';
import { formatDate } from '../../shared/utils';
import { Result } from '../../models/common/paged_result';
import { toast } from 'react-toastify';
import { DocumentTitle } from '../../app/components/document_title';

const CampaignList = () => {
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const loadCampaigns = () => {
    setLoading(true);
    campaignApi.get<Result<Campaign[]>>(`/campaign/list`)
      .then(response => {
        //console.log(response.data);
        setCampaigns(response.data?.result)}
      )
      .catch(() => toast.error("Unable to load campaigns"))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadCampaigns();
  }, []);

  const handleDeleteCampaign = async (campaignId: number) => {
    try {
      setSubmitting(true);
      await campaignApi.del(`/campaign/${campaignId}/delete`);
      toast.success("Campaign deleted successfully.");
      loadCampaigns();
    } catch (error) {
      toast.error("Failed to delete campaign.");
    } finally {
      setSubmitting(false);
      setIsDeleteModalOpen(false);
    }
  };

  const openDeleteModal = (campaign: Campaign) => {
    setSelectedCampaign(campaign);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setSelectedCampaign(null);
    setIsDeleteModalOpen(false);
  };

  return (
    <>
    <DocumentTitle title={"Campaigns"} />
    <div>
      <h1>Campaigns</h1>
      <Button primary onClick={() => navigate('/admin/campaigns/edit/0')}>
        <Icon name='add' /> Add Campaign
      </Button>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign='center'>Name</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Phone Number</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Active</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Created Date</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Msgs</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Users</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {campaigns?.map(campaign => (
            <Table.Row key={campaign.id}>
              <Table.Cell>{campaign.name}</Table.Cell>
              <Table.Cell textAlign='center'>{campaign.campaignPhone}</Table.Cell>
              <Table.Cell textAlign='center'>{campaign.active ? 'Yes' : 'No'}</Table.Cell>
              <Table.Cell textAlign='center'>{formatDate(campaign.createdDate)}</Table.Cell>
              <Table.Cell textAlign='center'><a href={`/admin/campaign-msgs/${campaign.id}`}>{campaign.msgCount}</a></Table.Cell>
              <Table.Cell textAlign='center'><a href={`/admin/campaign-users/${campaign.id}`}>{campaign.userCount}</a></Table.Cell>
              <Table.Cell textAlign='center'>
                <Button icon onClick={() => navigate(`/admin/campaigns/edit/${campaign.id}`)}>
                  <Icon name='edit' />
                </Button>
                <Button icon onClick={() => openDeleteModal(campaign)}>
                    <Icon name='delete' color='red' />
                  </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {loading && <p>Loading...</p>}
        {/* Delete Confirmation Modal */}
        <Modal size='tiny' open={isDeleteModalOpen} onClose={closeDeleteModal}>
          <Modal.Header>Delete Campaign</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete the campaign <strong>{selectedCampaign?.name}</strong> - all messages and users of this campaign?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={closeDeleteModal}>Cancel</Button>
            <Button negative onClick={() => handleDeleteCampaign(selectedCampaign!.id)} loading={submitting} disabled={submitting}>Delete</Button>
          </Modal.Actions>
        </Modal>
    </div>
    </>
  );
};

export default CampaignList;
