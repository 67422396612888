import axios, { HttpStatusCode } from 'axios';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { createContext, useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { User, UserRole } from '../../models/user/user';

interface AuthState {
  isAuthenticated: boolean;
  token: string | null;
  user: User | null;
}

interface AuthContextType extends AuthState {
  login: (username: string, password: string) => Promise<{ isValid: boolean; errors: string[] }>;
  logout: () => void;
  refreshToken: () => void;
  accountSwitch: (accountId: number, accountName: string) => void;
  locationSwitch: (locationId: number, locationName: string) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

interface AuthProviderProps {
  children: React.ReactNode;
}
export function AuthProvider({ children }: AuthProviderProps) {

  const [authState, setAuthState] = useState<AuthState>({
    isAuthenticated: false,
    token: null,
    user: null,
  });

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedUser = localStorage.getItem('user');

    if (storedToken && storedUser) {
      const parsedUser = JSON.parse(storedUser) as User;
      setAuthState({
        isAuthenticated: true,
        token: storedToken,
        user: parsedUser,
      });
    }
  }, []);

  const navigateTo = (path: string) => {
    window.location.href = path;
  };

  const checkTokenValidity = (token: string) => {
    try {
      const decodedToken = jwtDecode<JwtPayload>(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
      const bufferTime = 5 * 60; // 5 minutes buffer
      if (!decodedToken.exp || decodedToken.exp < currentTime + bufferTime) {
        return false;
      }
      return true;
    } catch (error) {
      console.error('Error decoding token:', error);
      throw error;
    }
  };

  const validationSchema = Yup.object({
    login: Yup.string()
      .required('Required')
      .min(4, 'Login must be at least 4 characters')
      .max(50, 'Login must be at most 50 characters'),
    pwd: Yup.string()
      .required('Required')
      .min(4, 'Password must be at least 4 characters')
      .max(25, 'Password must be at most 25 characters'),
  });

  const login = async (username: string, password: string) => {
    const data = { login: username, pwd: password };
    try {
      await validationSchema.validate(data, { abortEarly: false });
    } catch (errors) {
      const errorArray = (errors as Yup.ValidationError).errors as string[];
      return { isValid: false, errors: errorArray };
    }

    try {
      axios.defaults.withCredentials = true;
      const response = await axios.post<any>('auth/login', data);
      if (response.status === HttpStatusCode.Ok) {
        if (response.headers) {
            const remappedAuthorization = response.headers['x-amzn-remapped-authorization'];
            const authorization = response.headers['authorization'];
            const token = remappedAuthorization ?? authorization;

            const user = response.data as User;
            const jwtToken = token ? token.replace('Bearer ', '') : null;

            localStorage.setItem('token', jwtToken);
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('accountId', response.data.accountId.toString());
            localStorage.setItem('accountName', response.data.accountName);

            setAuthState({
                isAuthenticated: true,
                token,
                user,
            });

            return { isValid: true, errors: [] };
        }
    }

      return { isValid: false, errors: ['Missing authorization headers. Please check your credentials'] };
    } catch (error) {
      //console.log(error);
      return { isValid: false, errors: ['Login failed. Please check your credentials'] };
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('accountId');
    localStorage.removeItem('accountName');

    setAuthState({
      isAuthenticated: false,
      token: null,
      user: null,
    });

    navigateTo("/login");

  };

  const refreshToken = async (): Promise<string | undefined> => {
    try {
      const existingToken = localStorage.getItem('token');
      if (existingToken) {
        const isTokenValid = checkTokenValidity(existingToken);
        if (isTokenValid) {
          return existingToken;
        }
      }
      axios.defaults.withCredentials = true;
      const response = await axios.post<any>('auth/renew-token');
      if (response.headers && response.headers['authorization']) {
        const token = response.headers['authorization'];
        const jwtToken = token ? token.replace('Bearer ', '') : null;
        localStorage.setItem('token', jwtToken);
        localStorage.setItem('user', JSON.stringify(response.data));
        return jwtToken;
      }
    } catch (error) {
      console.error('Login failed:', error);
      logout();
      return undefined;
    }
    return undefined;
  };

  const accountSwitch = async (accountId: number, accountName: string) => {
    const user = authState.user;
    if (user?.role === UserRole.SuperAdmin){
      localStorage.setItem('accountId',  accountId?.toString());
      localStorage.setItem('accountName', accountName);

      user.accountId = accountId;
      user.accountName = accountName;
      localStorage.setItem('user', JSON.stringify(user));
      setAuthState(prevState => ({
        ...prevState,
        user: user,
      }));
    }
  }

  const locationSwitch = async (locationId: number, locationName: string) => {
    const user = authState.user;
    if (user?.role === UserRole.SuperAdmin || user?.role === UserRole.AccountAdmin){
      localStorage.setItem('locationId',  locationId?.toString());
      localStorage.setItem('locationName', locationName);

      user.locationId = locationId;
      user.locationName = locationName;
      localStorage.setItem('user', JSON.stringify(user));
      setAuthState(prevState => ({
        ...prevState,
        user: user,
      }));
    }

    const cacheKey = `locations_for_${user?.id}_${user?.accountId}_${user?.locationId}`;
    localStorage.removeItem(cacheKey);
  }

  return (
    <AuthContext.Provider value={{  ...authState, login, logout, refreshToken, accountSwitch, locationSwitch }}>
      {children}
    </AuthContext.Provider>
  );
}
